<template>
    <div>

        <section class="section">
            <div class="container has-text-centered">
                <h1 class="title is-3">{{ $t('Recommended security tokens') }}</h1>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <p>{{ $t('Select the country of residence of your company') }}:</p>
                    <div style="height: 1em;"></div>
                    <div class="columns is-centered has-text-centered">
                        <div class="column is-6">
                            <div class="select is-fullwidth">
                                <select name="country" v-model="selectedCountryId">
                                    <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                                </select>
                            </div>
                            <div style="height: 2em;"></div>
                        </div>
                    </div>
                    <div class="columns has-text-centered" v-if="selectedCountryId == 42">
                        <div class="column" v-if="selectedCountryId != 3">
                            <figure class="image is-96x96 is-center"><img src="@/assets/img/part_aknur.png"></figure>
                            <a href="http://aknur.kz/etoken-dlya-samruk-kazyna/etoken-dlya-polzovateley-nerezidentov-isez-ao-fnb-samruk-kazyna.html?lang=ru" target="_blank">SafeNet eToken 5110</a>
                        </div>
                        <div class="column" v-if="selectedCountryId == 42">
                            <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin.png"></figure>
                            <a href="http://www.ealaddin.com.ua/7-novosti/78-autentifikatsiya-na-torgovykh-ploshchadkakh.html" target="_blank">SafeNet eToken 5110</a>
                        </div>
<!--                        <div class="column" v-if="selectedCountryId != 3">-->
<!--                            <figure class="image is-96x96 is-center"><img src="@/assets/img/part_iepir.png"></figure>-->
<!--                            <a href="https://iepirkumi.lv/ru/drugije_servisi/kazahstan" target="_blank">JaCarta JC 100 </a>-->
<!--                        </div>-->
                        <div class="column" v-if="selectedCountryId != 3">
                            <figure class="image is-96x96 is-center" style="border: 1px solid #d0d1d1;padding: 40px 5px 0px;border-radius: 10px;"><img src="@/assets/img/part_thales.jpg"></figure>
                            <a href="https://cpl.thalesgroup.com/access-management/authenticators/pki-usb-authentication/etoken-5110-usb-token" target="_blank">SafeNet eToken 5110</a>
                        </div>
                    </div>
                    <div class="columns has-text-centered" v-else-if="selectedCountryId == 3">
                      <div class="column" v-if="selectedCountryId == 3">
                        <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin2.png"></figure>
                        <a href="https://iecp.ru/services/nositeli/token-dlya-samruk-kazyna" target="_blank">JaCarta PKI/ГОСТ</a>
                      </div>
                    </div>
                    <div class="columns has-text-centered" v-else-if="selectedCountryId != 3">
                      <div class="column"></div>
                      <div class="column" v-if="selectedCountryId != 3">
                        <figure class="image is-96x96 is-center"><img src="@/assets/img/part_aknur.png"></figure>
                        <a href="http://aknur.kz/etoken-dlya-samruk-kazyna/etoken-dlya-polzovateley-nerezidentov-isez-ao-fnb-samruk-kazyna.html?lang=ru" target="_blank">SafeNet eToken 5110</a>
                      </div>
                      <div class="column" v-if="selectedCountryId != 3">
                        <figure class="image is-96x96 is-center" style="border: 1px solid #d0d1d1;padding: 40px 5px 0px;border-radius: 10px;"><img src="@/assets/img/part_thales.jpg"></figure>
                        <a href="https://cpl.thalesgroup.com/access-management/authenticators/pki-usb-authentication/etoken-5110-usb-token" target="_blank">SafeNet eToken 5110</a>
                      </div>
                      <div class="column"></div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
    export default {
      data () {
        return {
          selectedCountryId: null,
          countries: null,
        }
      },
      mounted () {
        this.$http.get('/query/countries/').then(response => {
          this.countries = response.data
          this.$http.get('/query/get-country/').then(response => {
            if (response.data) {
              console.log(response.data)
              const country = this.countries ? this.countries.find(item => item.name === response.data) : null
              this.selectedCountryId = country.id
            } else {
              this.selectedCountryId = '2'
            }
          })
        })
      }
    }
</script>
<style scoped>
    .is-center {
        margin: auto;
    }
</style>
